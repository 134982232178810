import React from 'react'
import CountUp from 'react-countup'
import { PiStudentFill } from 'react-icons/pi'
import { DiAndroid, DiApple } from "react-icons/di";


const Section2 = () => {
  return (
    <>
    <div className="container pt-3">
        <div className='row'>
            <div className='col-md-2 course_section1'>1
                <div className='row'>
                    <div className='col-md-5'>
                        <PiStudentFill style={{ fontSize: "50px", alignItems: "center"}}/>
                    </div>
                    <div className='col-sm-7'>
                        <div className="col" style={{ fontSize: "30", alignItems: "center"}}>
                            BCA</div>
                        <div className="col">
                            <CountUp end={300} duration={10}/>+
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-2 course_section2'>2
            <div className='row'>
                    <div className='col-md-5'>
                        <DiAndroid style={{ fontSize: "50px", alignItems: "center"}}/>
                    </div>
                    <div className='col-sm-7'>
                        <div className="col" style={{ fontSize: "30px", alignItems: "center", margin: "-10px"}}>
                            Android</div>
                        <div className="col">
                            <CountUp end={300} duration={10}/>+
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-2 course_section3'>3
            <div className='row'>
                    <div className='col-md-5'>
                    <DiApple style={{fontSize: "50px",alignItems: "center"}}/>
                    </div>
                    <div className='col-sm-7'>
                        <div className="col" style={{ fontSize: "50px",alignItems: "center"}}>
                            BCA</div>
                        <div className="col">
                            <CountUp end={300} duration={10}/>+
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-2 course_section4'>4
            <div className='row'>
                    <div className='col-md-5'>
                        <PiStudentFill style={{ fontSize: "50px", alignItems: "center"}}/>
                    </div>
                    <div className='col-sm-7'>
                        <div className="col" style={{ fontSize: "30", alignItems: "center"}}>
                            BCA</div>
                        <div className="col">
                            <CountUp end={300} duration={10}/>+
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Section2
