import React from 'react'
import { MdEmail, MdLock } from "react-icons/md";
const Login = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
          <img src="./mg4.jpg" style={{height: 350,borderRadius:"20px" }}></img>
          </div>
          <div className="col-md-6 login_1">
            <h3 className='pb-3'>Login</h3>
            <form action='' className='login_style'>
              <div className='input_div'>
                <MdEmail style={{marginRight:"10px",color:"white"}}/>
                <input type='email' placeholder='Email' name='' id=''/>
              </div>
              <div className='input_div'>
                <MdLock style={{marginRight:"10px", color:"white"}}/>
                <input type='password' placeholder='Password' name='' id=''/>
              </div>
              <div className='signup_links'>
                <div><a href='#'>Sing Up</a></div>
                <div><a href='#'>Forgot password</a></div>
              </div>
              <button className='Submit_btn1'>Login</button>
            </form>
            </div>
        </div>
      </div>
    </>
  )
}

export default Login
