import React from 'react'

const AboutUs = () => {
  return (
    <>
      <div className='container klkl_2'>
        <div className='row'>
            <div className='col-md-6'>
                <img src="./mg4.jpg" style={{height: 350}}></img>
            </div>
            <div className='col-md-6'>
                <h1>AboutUs</h1>
                Hello !
Nivedan here again!

I've been helping many of my entrepreneur and creator friends improve their AI skills lately.

Today, I'll demonstrate prompt engineering by showing the difference between good and bad prompts.

But first, check out my next prompting masterclass & become an AI champ yourself! 👇</div>
        </div>
      </div>
      <div className='contaianer kol_md'>
        <div className='row'>
          <h2>Facilities</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi aperiam enim porro nulla ipsam eos repudiandae vero eum ipsum! Eaque aperiam sunt quas neque error illo ullam sit tempora nihil, nostrum id culpa architecto fuga accusamus! Labore eaque eos praesentium accusamus, exercitationem voluptas autem sit quae quaerat. Ullam, enim totam!
        </p>
        </div>
        <h2>Facilities Fetures</h2>
        <ul>
          <li>color</li>
          <li>color2</li>
          <li>color3</li>
          <li>color4</li>
          <li>color5</li>
          <li>color5</li>
          <li>color6</li>
        </ul>
      </div>
    </>
  )
}

export default AboutUs
