import React from 'react'
import { IoCallOutline, IoHomeOutline, IoMailOutline, IoWalkOutline } from 'react-icons/io5'

const ContactUs = () => {
  return (
   
        <>
            <div className='container reach_1'>
                <div class="row">
                    <div className="col-md-6">
                        <div className="row">
                            <h3>Reach Us</h3>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-4">
                                        <IoHomeOutline style={{ fontSize: "35px", color: "rgb(12,123,192)" }} />
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <h3 className='contact_headings'>Address</h3>
                                            <p style={{ fontSize: "14px" }}>
                                                1245 Whitehorse Mercerville Road, suite 410-411,
                                                Hamiton,New Jersey-08619
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-4">
                                        <IoCallOutline style={{ fontSize: "35px", color: "rgb(12,123,192)" }}/>
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <h3 className='contact_headings'>Phone</h3>
                                            <p style={{ fontSize: "20px" }}>
                                            +91 8239759045
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-4">
                                        <IoMailOutline style={{ fontSize: "35px", color: "rgb(12,123,192)" }}/>
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <h3 className='contact_headings'>E-mail</h3>
                                            <p style={{ fontSize: "20px" }}>
                                                mustakimmugal824@gmail.com
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-4">
                                        <IoWalkOutline style={{ fontSize: "35px", color: "rgb(12,123,192)" }}/>
                                        
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <h3 className='contact_headings'>WORK</h3>
                                            <p style={{ fontSize: "14px" }}>
                                                ME FREE HU MUJE KOI KHAM DEDO
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-md-6">
                        <form action="" className="Contact_form">
                            <input type="text" placeholder='first name'/>
                            <input type="text" placeholder='last name'/>
                            <input type="text" placeholder='phone number'/>
                            <input type="email" placeholder='email'/>
                            <textarea placeholder='Message' rows={5}/>
                            <button type='submit' className='Submit_btn'>Submit</button>
                        </form>
                    </div>

                </div>

            </div>
            <div className="container">
                <div className="row">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.266870295897!2d75.16920108707521!3d27.58525466964915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399a7dc000000001%3A0x5c5f71003d4717df!2sShekhawati%20Group%20of%20Institutions-Best%20B.Tech%2C%20BCA%2C%20MCA%2C%20BBA%2C%20MBA%20College%20in%20Sikar!5e0!3m2!1sen!2sin!4v1733734590109!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </>

  )
}

export default ContactUs

