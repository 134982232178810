import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Home from './Component/Home'
import Header from './Component/Header'
import Footer from './Component/Footer'
import './Style.css'
import ContactUs from './Component/ContactUs'
import AboutUs from './Component/AboutUs'
import Login from './Component/Login'
const App = () => {
  return (
    <>
      <Router>
        <Header/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='contactUs' element={<ContactUs/>}/>
          <Route path='AboutUs' element={<AboutUs/>}/>
          <Route path='Login' element={<Login/>}/>
        </Routes>
        <Footer/>
      </Router>
    </>
  )
}

export default App

