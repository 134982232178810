import React from 'react'
import Section1 from './Section1'
import Section2 from './Section2'

const Home = () => {
  return (
    <>
     <Section1/>
     <Section2/>
     
    </>
  )
}

export default Home
