import React from 'react'

const Section1 = () => {
  return (
    <>
      <div className='container numbu_1'>
        <div className='row'>
            <div className='col-md-6'>
                <img src="./mg3.png" style={{maxHeight: 350}}></img>
            </div>
            <div className='col-md-6' >
                <h3>Collage management System</h3>
                Hello !
Nivedan here again!

I've been helping many of my entrepreneur and creator friends improve their AI skills lately.

Today, I'll demonstrate prompt engineering by showing the difference between good and bad prompts.

But first, check out my next prompting masterclass & become an AI champ yourself! 👇</div>
        </div>
      </div>
    </>
  )
}

export default Section1
