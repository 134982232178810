import React from "react";
import { IoIosLogIn } from "react-icons/io";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <Link a class="navbar-brand" to="Home" style={{color: "blue"}}>
          M3l
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
          
              <Link a class="nav-item active nav-link" to="Home">
                Home
              </Link>
            
              <Link a class="nav-item nav-link Acive" to="Features">
                Features
              </Link>
            
              <Link a class="nav-item nav-link Active" to="Pricing">
                Pricing
              </Link>

              <Link a class="nav-item nav-link Active" to="ContactUs">
                ContactUs
              </Link>
              <Link a class="nav-item nav-link Active" to="AboutUs">
                AboutUs
              </Link>

              <Link a class="nav-item nav-link Active" to="Login">
              <IoIosLogIn />Login
              </Link>

          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
