import React from "react";

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white text-center py-4 mt-5">
      <div className="container">
        <p className="mb-0">© 2024 M3l. All rights reserved.</p>
        <p>
          <a href="#" className="text-white me-3">
            Privacy Policy
          </a>
          |
          <a href="#" className="text-white ms-3">
            Terms & Conditions
          </a>
        </p>

        <div className="social-icons mt-3">
          <a href="#" className="text-white me-3">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#" className="text-white me-3">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#" className="text-white me-3">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="#" className="text-white me-3">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>

      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
      />
    </footer>
  );
};

export default Footer;

